import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CacheService } from '../cache.service';
import { TimerService } from '../time.service';

@Injectable()
export class HttpCacheInterceptor implements HttpInterceptor {
  constructor(
    private timerService: TimerService,
    private cacheService: CacheService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.get('cacheResponse')) {
      return next.handle(req.clone());
    } else {
      this.timerService.startTimer();
      const remainingTimeInMilliseconds = this.timerService.getRemainingTime();

      if (remainingTimeInMilliseconds <= 0) {
        this.timerService.resetTimer();
        this.cacheService.invalidateCache();
      }

      if (req.method === 'GET') {
        const cachedResponse:
          | HttpResponse<any>
          | undefined = this.cacheService.get(req.urlWithParams);

        if (cachedResponse) {
          return of(cachedResponse);
        }

        return next.handle(req).pipe(
          tap((event) => {
            if (event instanceof HttpResponse) {
              this.cacheService.put(req.urlWithParams, event);
            }
          })
        );
      }
      else {
          return next.handle(req);
      }
    }
  }
}
