import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TranslateLoader } from '@ngx-translate/core';

import { WINDOW } from '../providers/window.provider';
import { EnvironmentService } from './environment-service/environment.service';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader  {
    contentHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'catchError': 'false',
      'skipAuthorizationHeader': 'true' });

    constructor(
      private http: HttpClient,
      private environmentService: EnvironmentService,
      @Inject(WINDOW) private window: Window,
    ) {}

    getTranslation(lang: string): Observable<any> {
        const blobUrl = this.environmentService.getEnvironment().blobStorageUrl;
        const hostName = this.window.location.hostname
        const translationUrl = `${blobUrl}/templates/${hostName}-${lang}.json`;

        return forkJoin([
          this.http.get<Record<string, unknown>>(`./assets/i18n/${lang}.json`).pipe(
            catchError(error => {
              console.error('Failed to load default translation for language: ' + lang, error);
              return of({});
            })
          ),
          this.http.get<Record<string, unknown>>(translationUrl, { headers: this.contentHeader }).pipe(
            catchError(() => {
              // whitelabel translations not found
              return of({});
            })
          )]
        )
          .pipe(map(results => {
            const [defaultTranslations, whiteLabelTranslations] = results;
            return {
              ...defaultTranslations,
              ...whiteLabelTranslations
            }
          }))
    }
}
